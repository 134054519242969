.country-container {
    padding: 30px;
    // min-height: 300px;
    // max-height: 450px;
    .field-container {
        display: flex;
        align-items: center;
        height: 42px;
        border-radius: 5px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
        background-color: #fafafa;
        .country-field-input {
            width: 196px;
            border-radius: 5px;
            border: solid 1px #dcdcdc;
            background-color: #fafafa;
            border: unset !important;
            display: flex;
            flex: 1 1;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            height: 29px;
        }
    }
    .country-code-modal {
        margin-top: 15px;
        overflow-y: scroll;
        min-height: 300px;
        max-height: 450px;
        padding-inline-start: 0px;
        .country-code-text {
            padding: 10px 5px 5px 10px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }
    }
    .short-list {
    }
}
