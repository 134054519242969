.forgot-reset-password-parent-wrapper {
    position: fixed;
    width: 40%;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    background-color: #f4f7fb;
    border-radius: var(--border-radius-5);
    height: fit-content;
    max-width: 500px;
    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 90%;
    }
    .close-image {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 100%;
            width: 2px;
            background-color: white;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    img {
        width: 100%;
    }
    .title {
        color: var(--blue);
        font-weight: var(--font-weight-bold);
        line-height: 27px;
        font-size: 20px;
        width: fit-content;
        margin: 24px auto 24px;
    }
    .description {
        width: fit-content;
        max-width: 70%;
        margin: 0 auto 16px;
        font-size: var(--font-12);
        color: var(--blue);
    }
    .details-wrapper {
        .details {
            width: 90%;
            margin: auto;
            font-size: var(--font-12);
            line-height: 16px;
            color: var(--blue);
            margin-bottom: 15px;
            label {
                margin-left: 17px;
            }
            .custom-input-adjustments {
                width: 100%;
            }
            .password-image {
                width: 20px;
                position: absolute;
                top: 14px;
                right: 10px;
            }
        }
    }
    .btn-wrapper {
        width: 100%;
        margin: 29px auto 18.5px;
        .adjustments {
            min-width: 60%;
            margin: auto;
            display: block;
        }
    }
    .back-login {
        text-decoration: underline;
        cursor: pointer;
        font-size: var(--font-12);
        font-weight: var(--font-weight-bold);
        color: var(--blue);
        width: fit-content;
        margin: 18.5px auto 28px;
    }
}
