@import '~bootstrap/scss/bootstrap.scss';

:root {
    --primary: #216cbb;
    --blue: #56648d;
    --pink: #ed7474;
    --secondary: #737a90;
    --gray: #868686;
    --border-radius-5: 5px;
    --font-12: 12px;
    --font-14: 14px;
    --font-16: 16px;
    --font-weight: 500;
    --font-weight-bold: bold;
}
