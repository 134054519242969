.custom-input-wrapper {
    position: relative;
    .custom-input {
        height: 40px;
        border-radius: var(--border-radius-5);
        border: solid #c8d0d9;
        border-width: 1px;
        background-color: #fcfcfc;
        color: var(--gray);
        font-size: var(--font-14);
        font-weight: unset;
        padding: 0 16px;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: var(--gray);
            font-size: var(--font-14);
        }
        &:-webkit-autofill {
            -webkit-text-fill-color: var(--gray);
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px #fcfcfc;
        }
    }
}
