.recruiter-detail-parent-wrapper {
    border-radius: 5px;
    background-color: #f4f7fb;
    box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 70%;
    margin: 20px auto 5px;
    padding: 10px;
    @media (max-width: 1000px) {
        width: 80%;
    }
    @media (max-width: 800px) {
        width: 90%;
    }
    @media (max-width: 600px) {
        width: 98%;
    }
    > section:nth-child(1) {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        border-radius: var(--border-radius-5);
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 3px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        .details {
            figure {
                position: relative;
                margin: 0;
                img {
                    width: 108px;
                    height: 108px;
                    object-fit: cover;
                    border-radius: 100%;
                    margin-top: 10px;
                }
                .recruiter-name-initials {
                    position: absolute;
                    top: 2%;
                    margin: 0;
                    font-weight: bold;
                    font-size: 2.6rem;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }

            > div {
                text-align: center;
                margin: 10px 0;
                font-size: 12px;
                color: var(--blue);
                .name {
                    font-weight: var(--font-weight-bold);
                }
            }
        }
        .btn-wrapper {
            display: flex;
            align-items: center;
            button {
                background: #5685cb;
            }
        }
    }
    .jobs {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        grid-gap: 10px;
        max-height: 100%;
        overflow-y: auto;
        margin-bottom: 10px;
        padding: 10px 2px;
        @media (max-width: 1024px) {
            grid-template-columns: 49% 49%;
        }
        @media (max-width: 700px) {
            grid-template-columns: 100%;
        }
        section {
            background: #ffffff;
            border-radius: var(--border-radius-5);
            box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 3px -1px rgba(0, 0, 0, 0.12),
                0 1px 3px 0 rgba(0, 0, 0, 0.2);
            padding: 5px;

            .first-row {
                font-size: 12px;
                color: var(--blue);
                margin: 10px 8px 10px 8px;
                display: flex;
                justify-content: space-between;
                .job-title-company-exp-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .job-title {
                        font-weight: bold;
                        margin-bottom: 0.5em;
                    }
                    .company-name-exp {
                        display: flex;
                        flex-wrap: wrap;
                        font-size: 9px;
                        color: var(--blue);
                        justify-content: space-between;
                        margin-bottom: 0.5em;
                    }
                    .exp {
                        color: var(--blue);
                        font-size: 9px;
                        white-space: nowrap;
                        margin-bottom: 0.5em;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                    }
                }
                .location-salary-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .salary {
                        font-size: 9px;
                        color: var(--blue);
                        font-weight: 800;
                    }
                    .location {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: initial;
                        display: -webkit-box;
                        font-size: 9px;
                        text-align: right;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        img {
                            margin-right: 0.34em;
                        }
                    }
                }
            }
            .second-row {
                text-align: center;
                margin: 0 8px 10px 8px;
                display: flex;
                justify-content: space-between;
                .category {
                    color: #ffffff;
                    font-size: 9px;
                    background-color: var(--secondary);
                    white-space: nowrap;
                    height: 24px;
                    border-radius: 11px;
                    padding: 2px 10px;
                    margin-right: 10px;
                    margin-top: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .adjustment {
                    width: 50%;
                    button {
                        margin: 0;
                        width: 100%;
                        min-width: 50px;
                        height: 30px;
                        margin-right: 15px;
                        background-color: #5685cb;
                        border-radius: 5px;
                    }
                }
                .btn-disabled-applied {
                    width: 50%;
                    button {
                        background: unset !important;
                        color: #5685cb !important;
                        border: solid #5685cb;
                    }
                }
            }
            .third-row {
                display: flex;
                flex-wrap: wrap;
                font-size: 10px;
                color: var(--gray);
                justify-content: space-between;
                margin: 0 8px 10px 8px;

                .location {
                    display: flex;
                    img {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}
.custom-loader-backdrop {
    top: 100px;
}
