@import '../../../index.scss';

.terms-and-conditions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18.5px 24px 18.5px 24px;
    p {
        font-size: 9px;
        color: #353131;
        font-weight: 500;
        margin-bottom: 0;
        text-align: center;
    }
}
.signup-parent-wrapper {
    position: fixed;
    width: 40%;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    background-color: #f4f7fb;
    border-radius: var(--border-radius-5);
    height: fit-content;
    max-height: 90%;
    overflow-y: auto;
    max-width: 500px;
    @extend .custom-child-scrollbar-global;
    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 90%;
    }
    .close-image {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 9px;
            content: ' ';
            height: 100%;
            width: 2px;
            background-color: white;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    img {
        width: 100%;
    }
    .fb-connect {
        width: 100%;
        margin: 26px auto 28px;
        .adjustments {
            background-color: #507cc0;
            line-height: 18px;
            min-width: 80%;
            margin: auto;
            display: block;
            span {
                font-size: 25px;
                font-weight: var(--font-weight-bold) er;
                vertical-align: sub;
                margin-right: 4px;
            }
        }
    }
    .title {
        color: var(--blue);
        font-weight: var(--font-weight-bold);
        line-height: 27px;
        font-size: 20px;
        width: 90%;
        margin: 26px auto 24px;
        text-align: center;
    }
    .details-wrapper {
        .details {
            width: 90%;
            margin: auto;
            font-size: var(--font-12);
            line-height: 16px;
            color: var(--blue);
            margin-bottom: 15px;
            label {
                margin-left: 17px;
            }
            .custom-input-adjustments {
                width: 100%;
            }
            .password-image {
                width: 20px;
                position: absolute;
                top: 14px;
                right: 10px;
            }
            .phone-number {
                display: flex;
                .country-code {
                    height: 40px;
                    border-radius: var(--border-radius-5);
                    border: solid #c8d0d9;
                    border-width: 1px;
                    background-color: #fcfcfc;
                    color: var(--gray);
                    font-size: var(--font-14);
                    font-weight: unset;
                    padding: 0 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right-width: 0;
                }
                .phone-number-input-parent {
                    width: 100%;
                }
                .custom-input-adjustments {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
    .referral {
        font-size: var(--font-12);
        line-height: 16px;
        font-weight: var(--font-weight-bold);
        color: var(--blue);
        text-decoration: underline;
        width: fit-content;
        margin: 31.5px auto 29px;
        cursor: pointer;
    }
    .referral-details {
        width: 90%;
        margin: auto;
        font-size: var(--font-12);
        line-height: 16px;
        color: var(--blue);
        margin-bottom: 15px;
        label {
            margin-left: 17px;
        }
        .custom-input-adjustments {
            width: 100%;
        }
    }
    .sign-up-btn-wrapper {
        width: 100%;
        margin: 29px auto 18.5px;
        .sign-up-adjustments {
            min-width: 60%;
            margin: auto;
            display: block;
        }
        button {
            background: #5685cb;
        }
    }
    .login,
    .signup {
        font-size: var(--font-12);
        font-weight: var(--font-weight-bold);
        color: var(--blue);
        width: fit-content;
        margin: 18.5px auto 28px;
        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .forgot-password {
        text-decoration: underline;
        cursor: pointer;
        font-size: var(--font-12);
        font-weight: var(--font-weight-bold);
        color: var(--blue);
        width: fit-content;
        margin: 18.5px auto 28px;
    }
}

.country-code-parent-wrapper {
    position: fixed;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 40%;
    z-index: 250;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    background-color: #f4f7fb;
    border-radius: var(--border-radius-5);
    height: fit-content;
    min-height: 40px;
    max-height: 90%;
    overflow-y: hidden;
    max-width: 400px;
    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 98%;
    }
    .close-image {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 18px;
        z-index: 252;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 12px;
            width: 2px;
            background-color: gray;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
