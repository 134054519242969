.parent-wrapper {
    .heading {
        font-size: 16px;
        font-weight: bold;
        padding: 1rem;
        text-align: center;
    }
    .download-success {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
    .field-container {
        height: 42px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        border: solid 1px #dcdcdc;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        width: 90%;
        margin: auto;
        .banner-field-input {
            width: 196px;
            border-radius: 5px;
            border: solid 1px #dcdcdc;
            background-color: #fafafa;
            border: unset !important;
            display: flex;
            flex: 1 1;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            height: 29px;
        }
        .banner-field-input-code {
            padding: 0 10px;
            width: 89px;
            background-color: #fafafa;
            border-left: unset !important;
            border: 0;
            border-right: 1px solid #dcdcdc;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            height: 29px;
            margin-right: 10px;
            color: #000;
            position: relative;
            cursor: pointer;
            .input-icon {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .banner-field-input-code:before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            background-image: url(https://jobget-static.s3.amazonaws.com/down-angle.svg);
            background-size: cover;
            right: 0px;
        }
        .banner-field-input-code .input-icon {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .active-btn {
        color: #fff;
        background-color: var(--primary);
        border-color: var(--primary);
        border-style: none;
        padding: 11px 35px;
        width: 90%;
        margin: auto;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 12px;
    }
    .disable-btn {
        opacity: 0.8;
        pointer-events: none;
    }
}
