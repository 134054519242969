@keyframes slideInBottomTop {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes slideInTopBottom {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}
.bottom-banner {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    color: #fff;
    background-color: #4d74c4;
    padding: 15px 34px;
    justify-content: space-between;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    animation: 2s ease-out 0s 1 slideInBottomTop;
    z-index: 90;
    height: 260px;
    max-width: 960px;
    margin: auto;
    .bottom-banner-right {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 0px;
        font-family: Poppins;
        font-style: normal;
        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            color: #ffffff;
            margin: 0px;
        }
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            color: #ffffff;
        }
        .bottom-banner-cta {
            display: flex;
            align-items: center;
            position: relative;
            input {
                background: rgba(244, 247, 251, 0.3);
                color: #ffffff;
                border-radius: 30px;
                width: 240px;
                height: 48px;
                border: unset !important;
                padding: 24px;
                font-weight: 400;
                margin-right: 16px;
                font-size: 14px;
                line-height: 21px;
            }
            .error-text {
                font-size: 12px;
                position: absolute;
                letter-spacing: 0.45px;
                color: #fff;
                bottom: -33px;
                left: 5px;
            }
            ::-webkit-input-placeholder {
                /* Edge */
                color: #ffffff;
            }
            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #ffffff;
                opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #ffffff;
            }
            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #ffffff;
            }
            button {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #216cbb;
                padding: 10px;
                background: #ffffff;
                border: 2px solid #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
                border-radius: 30px;
                width: 144px;
                outline: none;
            }
        }
    }
}
.close-bottom-banner {
    animation: 2s ease-out 0s 1 slideInTopBottom;
}
.bottom-banner-heading {
    display: block;
    font-size: 14px;
    font-weight: 600;
}
.bottom-banner-subheading {
    display: block;
    font-size: 12px;
    font-weight: 500;
}
.download-heading {
    font-size: 16px;
    font-weight: 700;
}
.banner-close {
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 14px;
    /* background: #fff; */
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    /* box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2); */
    cursor: pointer;
}
.download-success {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}
.mobile-banner-modal .modal-content {
    padding: 0 0 45px;
}
.banner-heading-img {
    padding: unset !important;
    position: relative;
    display: flex;
    justify-content: center !important;
}
.banner-heading-img .banner {
    width: 100%;
    height: 202px;
    object-fit: cover;
}
.banner-heading-img .logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
    position: absolute;
    bottom: -40px;
    border-radius: 10px;
}
.banner-heading-img button.close {
    right: 6px;
    top: 2px;
}
.mobile-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 27px;
}
.mobile-banner .title {
    margin: 16px 0;
    font-size: 18px;
    font-weight: 600;
}
.mobile-banner .info {
    font-size: 14px;
    font-weight: 500;
}
.dismiss-banner {
    cursor: pointer;
}
.disable-btn {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff !important;
    border-radius: 5px;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: 600;
    padding: 11px 35px;
    min-height: 48px;
    background-color: #7c8eb3;
    border: 1px solid #7c8eb3;
}
.bottom-button-disable {
    color: #216cbb;
    background: #fff;
    border: 0;
    border-left: 1px solid #216cbb;
}
.country-code-modal {
    max-height: 400px;
    margin-top: 20px !important;
    overflow-y: scroll;
}
.country-code-text {
    justify-content: space-between;
    cursor: pointer;
}
li.text-left.country-code-text {
    padding: 10px 29px 10px 10px;
    margin: 0 14px 0 0;
    font-weight: 400;
}

ul.short-list li {
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid #e4e2e2;
    align-items: center;
    color: #000;
    position: relative;
}
@media (max-width: 800px) {
    .bottom-banner {
        flex-direction: column;
        /* justify-content: center;   */
        z-index: 100;
        height: unset;
        background-color: rgba(33, 108, 187, 0.9);
        box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
    }
}
