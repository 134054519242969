.parent-wrapper {
    position: fixed;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 40%;
    z-index: 250;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    background-color: #f4f7fb;
    border-radius: var(--border-radius-5);
    height: fit-content;
    min-height: 40px;
    max-height: 90%;
    overflow-y: hidden;
    max-width: 400px;
    text-align: center;
    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 98%;
    }
    .close-image {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 18px;
        z-index: 252;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 12px;
            width: 2px;
            background-color: gray;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
