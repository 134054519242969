.custom-button {
    min-width: 166px;
    height: 48px;
    font-size: var(--font-14);
    font-weight: var(--font-weight);
    border-radius: var(--border-radius-5);
    &:focus {
        box-shadow: unset !important;
        outline: unset !important;
    }
    &:disabled {
        background-color: rgba(0, 0, 0, 0.37) !important;
    }
}
.btn-primary {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.btn-secondary {
    border-color: var(--secondary) !important;
    border-style: solid !important;
    color: var(--secondary) !important;
    background: unset !important;
    border-width: thin !important;
}
