html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(244, 247, 251);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font12 {
    font-size: var(--font-12);
}
.font14 {
    font-size: var(--font-14);
}
.font16 {
    font-size: var(--font-16);
}

.custom-scrollbar-global {
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.custom-child-scrollbar-global {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.error {
    font-size: 12px;
    color: red;
    margin-top: 2px;
}

.list-loading-text {
    text-align: center;
    font-size: 14px;
    color: var(--blue);
    &:before {
        content: '  .';
        animation: dots 1s steps(5, end) infinite;
    }
    @keyframes dots {
        0%,
        20% {
            color: rgba(0, 0, 0, 0);
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
            color: var(--blue);
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
            text-shadow: 0.25em 0 0 var(--blue), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
            text-shadow: 0.25em 0 0 var(--blue), 0.5em 0 0 var(--blue);
        }
    }
}
.cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

