.parent-wrapper {
    .heading {
        padding: unset !important;
        position: relative;
        display: flex;
        justify-content: center !important;
    }
    .heading .banner {
        width: 100%;
        height: 202px;
        object-fit: cover;
    }
    .heading .logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
        position: absolute;
        bottom: -40px;
        border-radius: 10px;
    }
    .body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 20px;
        margin-top: 27px;
    }
    .body .title {
        margin: 16px 0;
        font-size: 18px;
        font-weight: 600;
    }
    .body .info {
        font-size: 14px;
        font-weight: 500;
    }
    .btn {
        color: #fff;
        background-color: var(--primary);
        border-color: var(--primary);
        border-style: none;
        padding: 11px 35px;
        margin: auto;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
    }
    .dismiss-banner {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}
